<template>
  <div>
    <h3 class="pb-2">
      Customer Management
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.customerSearch"
    />
    <ValidationObserver ref="customerSearch">
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Customer Search</div>
        </div>
        <div class="card-body">
          <form @submit.prevent="onSubmit">
            <div class="row mb-2">
              <div class="col-md-6">
                <TextInput
                  rules="min:2|customerName|max:30"
                  name="First Name"
                  id="searchFirstName"
                  v-model="searchCriteria.firstName"
                />
              </div>
              <div class="col-md-6">
                <TextInput
                  rules="min:2|customerName|max:30"
                  name="Last Name"
                  id="searchLastName"
                  v-model="searchCriteria.lastName"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-4">
                <TextInput
                  rules="min:3|username|max:20"
                  name="Username"
                  id="searchUsername"
                  v-model="searchCriteria.username"
                />
              </div>
              <div class="col-md-4">
                <TextInput
                  rules="min:3|max:256"
                  name="Email"
                  id="searchEmail"
                  v-model="searchCriteria.email"
                />
              </div>
              <div class="col-md-4">
                <TextInput
                  rules="maskNumMin:3"
                  name="Phone"
                  id="searchPhone"
                  v-model="searchCriteria.phone"
                  mask="(###) ###-####"
                  placeholder="(___) ___-____"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <TextInput
                  rules="min:3|address|max:70"
                  name="Address"
                  id="searchAddress"
                  v-model="searchCriteria.address"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <TextInput
                  rules="min:3|city|max:50"
                  name="City"
                  id="searchCity"
                  v-model="searchCriteria.city"
                />
              </div>
              <div class="col-md-3">
                <ValidationProvider
                  name="State"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="searchState"
                    >State</label
                  >
                  <select
                    class="form-control form-control-lg"
                    id="searchState"
                    v-model="searchCriteria.state"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="(state, index) in states"
                      :key="index"
                      :value="state.abbreviation"
                      >{{ state.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-md-3">
                <TextInput
                  rules="min:3"
                  name="Zip"
                  id="searchZip"
                  v-model="searchCriteria.zip"
                  placeholder="_____-____"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <TextInput
                  rules="min:3|alphaNumSpaces|max:40"
                  name="Reservation Number"
                  id="searchResNumber"
                  v-model="searchCriteria.reservationNumber"
                />
              </div>
            </div>
            <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
              Search
            </button>
            <button
              type="button"
              class="btn btn-cancel btn-lg mr-2 mt-4"
              @click="resetForm"
            >
              Reset
            </button>
            <a
              role="button"
              class="btn btn-lg btn-success text-white mt-4"
              @click="$router.push('/admin/customer-add').catch(() => {})"
            >
              <i class="fa fa-plus-circle"></i>
              Add New Customer Profile
            </a>
          </form>
        </div>
      </div>
    </ValidationObserver>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Search Results</div>
      </div>
      <div class="card-body">
        <div class="d-flex mb-2">
          <div class="align-self-center">Items Per Page:</div>
          <div class="ml-2">
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              aria-label="Items Per Page"
            ></b-form-select>
          </div>
        </div>
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="loadData"
          stacked="xl"
          no-local-sorting
          :current-page="currentPage"
          :per-page="perPage"
          small
          bordered
          sort-icon-left
        >
          <template v-slot:cell(address)="data">
            {{ data.item.street1 }}
            <br />
            {{ data.item.street2 }}
          </template>
          <template v-slot:cell(zip)="data">
            <span v-if="data.item.country == 'US'">{{ data.item.zip | zipCode }}</span>
            <span v-else>{{ data.item.zip }}</span>
          </template>
          <template v-slot:cell(id)="data">
            <button
              class="btn btn-primary btn-sm m-1"
              @click="viewCustomer(data.item.id)"
            >
              View
            </button>
            <button
              class="btn btn-primary btn-sm m-1"
              @click="editCustomer(data.item.id)"
            >
              Edit
            </button>
          </template>
        </b-table>
        <div class="d-flex">
          <div class="mr-auto">
            Showing
            {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
            {{ perPage * currentPage - perPage + perPageDisplay }} of
            {{ totalRows }} entries
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import states from "@/store/modules/states.js";
import ProfileService from "@/services/admin/AdminProfileService.js";
export default {
  name: "AdminCustomerSearch",
  title: "Admin - Customer Management",
  components: {
    ValidationObserver,
    ValidationProvider,
    TextInput,
    FormErrorAlert
  },
  data() {
    return {
      errors: [],
      fields: [
        { key: "firstName", sortable: true },
        { key: "lastName", sortable: true },
        { key: "emailAddress", sortable: true },
        { key: "address", sortable: true },
        { key: "city", sortable: true },
        { key: "state", sortable: true },
        { key: "zip", sortable: true },
        {
          key: "phone",
          sortable: true,
          formatter: value => {
            return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(
              6
            )}`;
          }
        },
        { key: "id", label: "Actions/Options" }
      ],
      currentPage: 1,
      totalRows: 25,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      perPageDisplay: 25
    };
  },
  computed: {
    states() {
      return states;
    },
    searchCriteria() {
      return this.$store.getters["admin/customerSearchParams"] ?? {};
    },
    ...mapGetters("tenant", ["tenantId"])
  },
  methods: {
    onSubmit() {
      this.$refs.customerSearch.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.customerSearch.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.customerSearch.refs[errors[0]["key"]].$el.scrollIntoView(
              {
                behavior: "smooth",
                block: "center"
              }
            );
          }, 100);
        } else {
          this.errors = [];
          this.$refs.table.refresh();
          this.$store.commit("admin/setCustomerSearchParams", {
            ...this.searchCriteria
          });
        }
      });
    },
    resetForm() {
      this.errors = [];
      this.$store.commit("admin/setCustomerSearchParams", {});
      this.currentPage = 1;
      this.$refs.customerSearch.reset();
      this.$refs.table.refresh();
    },
    loadData(ctx) {
      let paging = {
        pageNumber: ctx.currentPage,
        itemsPerPage: ctx.perPage,
        sortField: ctx.sortBy,
        sortDescending: ctx.sortDesc
      };
      const profileService = new ProfileService(this.tenantId);
      this.searchCriteria.reservationNumber = this.searchCriteria
        .reservationNumber
        ? this.searchCriteria.reservationNumber.replace(/\s/g, "")
        : null;
      this.searchCriteria.zip = this.searchCriteria.zip ?? null;
      this.searchCriteria.phone = this.searchCriteria.phone ?? null;
      return profileService
        .search(this.searchCriteria, paging)
        .then(response => {
          this.currentPage = response.searchResults.pageNumber;
          this.totalRows = response.searchResults.totalItems;
          this.perPageDisplay = response.searchResults.itemsPerPage;
          return response.searchResults.data;
        });
    },
    viewCustomer(id) {
      this.$router.push(`/admin/customer-view/${id}`).catch(() => {});
    },
    editCustomer(id) {
      this.$router.push(`/admin/customer-edit/${id}`).catch(() => {});
    }
  },
  mounted(){
    this.$refs.table.refresh();
  }
};
</script>
